/* eslint-disable */
import Vue from 'vue'
import Vuetify from 'vuetify'
import VueEvents from 'vue-events'
import VueApexCharts from 'vue-apexcharts'
import wysiwyg from "vue-wysiwyg";
import VueTheMask from 'vue-the-mask'
import IdleVue from 'idle-vue'
import cookie from 'js-cookie'

import 'vuetify/dist/vuetify.min.css'
import "vue-wysiwyg/dist/vueWysiwyg.css";

Vue.use(VueApexCharts);
Vue.use(Vuetify);
Vue.use(VueEvents);
Vue.use(wysiwyg, {}); // config is optional. more below
Vue.use(VueTheMask);

require('./library/Calendar');

import cookies from "js-cookie";

import slim from 'jquery-slim';

global.jQuery = slim;

window.onload = function () {
    // A function to process messages received by the window.
    function receiveMessage(e) {
        if (!['', null, undefined].includes(e.data.local_storage)) {
            localStorage.setItem('_currentFiscalYear', e.data.local_storage._currentFiscalYear);
            localStorage.setItem('_permissions', e.data.local_storage._permissions);
            localStorage.setItem('_token', e.data.local_storage._token);
            localStorage.setItem('batch', e.data.local_storage.batch);
            localStorage.setItem('_role', e.data.local_storage._role);
            localStorage.setItem('_user', e.data.local_storage._user);
        }
        if (!['', null, undefined].includes(e.data.cookie)) {
            cookies.set('_cl', e.data.cookie._cl);
            cookies.set('_batch', e.data.cookie._batch);
            cookies.set('_company', e.data.cookie._company);
            cookies.set('_comm_tk', e.data.cookie._communicate_token);
            localStorage.setItem('_comm_tk', e.data.local_storage._user);
        }

        // Check to make sure that this message came from the correct domain.
        if (e.origin !== "http://localhost:8080") return;
        // if (e.origin !== "http://project.test") return;

        // Update the div element to display the message.
        localStorage.setItem("data", JSON.stringify(e.data));
    }

    // Setup an event listener that calls receiveMessage() when the window
    // receives a new MessageEvent.
    window.addEventListener('message', receiveMessage);
};
/** import dependencies **/
import router from './router'
import {store} from './store'

// const eventsHub = new Vue();
//60000 is 1 minute
// Vue.use(IdleVue, {eventEmitter: eventsHub, idleTime: 600000, store});

/** import library **/
import rest from '@/library/Rest'
/** Rest (Axios) API */
import storage from '@/library/Storage'
/** Web Storage API */
import auth from '@/library/Auth'
/** Authentication API */
import encryption from '@/library/Encryption'
/** AddressHelper API */
import AddressHelper from "./library/AddressHelper";
/** AddressHelper API */
import Permissions from "./library/Permissions";
/**
 * Nav Factory Class responsible to Show or Hide Nav Menu Items with bulk functions.
 */
import NavFactory from "./library/NavFactory";

/** Authentication API */


Vue.prototype.$rest = rest();
Vue.prototype.$storage = storage;
Vue.prototype.$auth = auth;
Vue.prototype.$encryption = encryption;
Vue.prototype.$slim = slim;
Vue.prototype.$address = new AddressHelper();
Vue.prototype.$permissions = new Permissions();
Vue.prototype.$navFactory = new NavFactory();

String.prototype.capitalizeFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

Number.prototype.pad = function () {
    return this.toString().padStart(2, "0");
};

/**
 * Format to given currency
 * @param  {String} format       Currency Format.
 * @param  {Number} decimalCount Total no of decimal points.
 * @param  {String} decimal      Decimal symbol.
 * @param  {String} thousands    Thousand separator.
 * @return {[type]}              String
 */
Number.prototype.currency = function (format = "Rs.", decimalCount = 2, decimal = ".", thousands = ",") {
    let amount = this;
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return format + " " + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        return 0;
    }
};

Array.prototype.diff = function (arr, key) {
    return this.filter(function (i) {
        return !(arr.includes(i[key]))
    });
};


Number.prototype.numberFormat = function (format = "", decimalCount = 2, decimal = ".", thousands = ",") {
    let amount = this;
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return format + " " + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        return 0;
    }
};
String.prototype.dateForm = function () {
    if (this.length === 8) return this.substr(0, 4) + '-' + this[4] + this[5] + '-' + this.slice(-2);
    else return this;
};

import App from './App.vue'
import Button from '@/components/Button'
import AddButton from '@/components/AddButton'
import EditButton from '@/components/EditButton'
import DeleteButton from '@/components/DeleteButton'
import ViewButton from '@/components/ViewButton'
import RefresButton from '@/components/RefreshButton'
import AddCategoryButton from '@/components/AddCategoryButton'
import AddUnitButton from '@/components/AddUnitButton'
import AddWareHouseButton from "./components/AddWareHouseButton";


Vue.component('apexchart', VueApexCharts);
Vue.component('Button', Button);
Vue.component('AddButton', AddButton);
Vue.component('EditButton', EditButton);
Vue.component('DeleteButton', DeleteButton);
Vue.component('ViewButton', ViewButton);
Vue.component('RefreshButton', RefresButton);
Vue.component('AddCategoryButton', AddCategoryButton);
Vue.component('AddUnitButton', AddUnitButton);
Vue.component('AddWareHouseButton', AddWareHouseButton);

/** Filters... */
Vue.filter('capitalize', (value) => {
    if (!value) return '';
    return value.toString().charAt(0).toUpperCase() + value.slice(1)
});

Vue.config.productionTip = false;

window.app = new Vue({
    router,
    store,
    render: h => h(App),
    onIdle() {
        // Logout if user is not active
        if (auth.authenticated()) {
            storage.clear();
            cookie.remove('_user');
            cookie.remove('_permissions');
            router.replace({name: 'login'});
        }
    },
    onActive() {
        // console.log('program is active...')
    }
}).$mount('#app');