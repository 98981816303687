<template>
  <v-chip
    :label="!circular"
    v-if="hasPermission"
    :class="{
      'eac-add-button': true,
      'loading-button': disabled,
    }"
    @click="!disabled ? $emit('action') : null"
    :color="color || 'primary'"
    text-color="white"
    :disabled="disabled"
  >
    <v-avatar>
      <v-icon>{{ icon || "pending" }}</v-icon>
    </v-avatar>
    <slot></slot>
  </v-chip>
</template>
<script>
export default {
  props: {
    icon: {
      required: false,
    },
    permission: {
      required: true,
    },
    color: {
      required: false,
    },
    circular: {
      required: false,
      default: true,
    },
    disabled: { required: false, default: false },
  },
  data: () => ({
    hasPermission: false,
  }),
  mounted() {
    if (this.permission) this.hasPermission = this.$auth.can(this.permission);
    // if (this.permission) {
    //     const requiredPermissions = this.permission.split('|');
    //     this.hasPermission = this.$permissions.hasPermission(requiredPermissions);
    // }
  },
};
</script>
<style lang="scss">
.eac-add-button {
  .v-chip__content {
    cursor: pointer;
  }
}
.loading-button {
  opacity: 0.4;
}
</style>
