/** Lazy Load View **/
function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/modules/inventory/_components/${view}.vue`)
}

let routes = [
    {
        name: 'warehouse',
        path: '/warehouse',
        component: loadView('Warehouse/index'),
        meta: {
            auth: true,
            permission: 'staff-create',
        }
    },{
        name: 'category',
        path: '/category',
        component: loadView('Category/index'),
        meta: {
            auth: true,
            permission: 'staff-create',
        }
    },{
        name: 'item',
        path: '/item',
        component: loadView('Item/index'),
        meta: {
            auth: true,
            permission: 'staff-create',
        }
    },{
        name: 'item-create',
        path: '/item-create',
        component: loadView('Item/create'),
        meta: {
            auth: true,
            permission: 'staff-create',
        }
    },{
        name: 'purchase',
        path: '/purchase',
        component: loadView('Purchase/create'),
        meta: {
            auth: true,
            permission: 'staff-create',
        }
    },
    {
        name: 'purchase-report',
        path: '/purchase-report',
        component: loadView('Purchase/index'),
        meta: {
            auth: true,
            permission: 'staff-create',
        }
    },{
        name: 'sales-report',
        path: '/sales-report',
        component: loadView('Sales/index'),
        meta: {
            auth: true,
            permission: 'staff-create',
        }
    },{
        name: 'sales',
        path: '/sales',
        component: loadView('Sales/create'),
        meta: {
            auth: true,
            permission: 'staff-create',
        }
    },{
        name: 'unit',
        path: '/unit',
        component: loadView('Unit/index'),
        meta: {
            auth: true,
            permission: 'staff-create',
        }
    },{
        name: 'item-edit',
        path: '/item/:id',
        component: loadView('Item/create'),
        meta: {
            auth: true,
            permission: 'staff-create',
        }
    },{
        name: 'purchase-return',
        path: '/purchase-return',
        component: loadView('Purchase/return'),
        meta: {
            auth: true,
            permission: 'staff-create',
        }
    },{
        name: 'sales-return',
        path: '/sales-return',
        component: loadView('Sales/return'),
        meta: {
            auth: true,
            permission: 'staff-create',
        }
    },{
        name: 'purchase-return-report',
        path: '/purchase-return-report',
        component: loadView('Purchase/return-report'),
        meta: {
            auth: true,
            permission: 'staff-create',
        }
    },{
        name: 'sales-return-report',
        path: '/sales-return-report',
        component: loadView('Sales/return-report'),
        meta: {
            auth: true,
            permission: 'staff-create',
        }
    }
];

export default routes;