<template>
    <!-- <v-btn outline small icon color="warning" v-if="$auth.can(permission)"><v-icon small>{{ icon || 'edit'}}</v-icon></v-btn> -->
    <v-dialog v-if="hasPermission" v-model="dialog" persistent max-width="290">
        <v-btn small icon color="error" class="pa-0 ma-1" slot="activator">
            <v-icon small>{{ icon || 'clear'}}</v-icon>
        </v-btn>
        <v-card>
            <v-card-title class="title pa-3 red white--text">
                <v-icon class="mr-2 white--text">warning</v-icon>
                Please, wait!
            </v-card-title>
            <v-card-text class="ma-0">{{message||'Are you sure you want to delete this item?'}}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" outline small @click="dialog = false, $emit('disagree')">No</v-btn>
                <v-btn color="error" outline small @click="dialog = false, $emit('agree')">Yes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        props: ['icon', 'permission', 'message'],
        data: () => ({
            dialog: false,
            hasPermission: false
        }),
        mounted() {
            if (this.permission)
            // this.hasPermission = this.$permissions.checkPermissions(this.permission);
                this.hasPermission = this.$auth.can(this.permission);

            // if (this.permission) {
            //     const requiredPermissions = this.permission.split('|');
            //     this.hasPermission = this.$permissions.hasPermission(requiredPermissions);
            // }
        }
    }
</script>