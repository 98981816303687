import ls from './../library/Storage'
import encryption from './Encryption'
import cookie from 'js-cookie'

const getRolesImage = (slug,) => {
    const images = [
        {
            name: 'administrator',
            avatar: 'https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/administrator.png'
        },
        {
            name: 'teacher',
            avatar: 'https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/teacher.png'
        },
        {
            name: 'accountant',
            avatar: 'https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/accountant.png'
        },
        {
            name: 'guardian',
            avatar: 'https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/guardian.png'
        },
        {
            name: 'director',
            avatar: 'https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/director.png'
        },
        {
            name: 'principal',
            avatar: 'https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/director.png'
        },
        {
            name: 'librarian',
            avatar: 'https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/librarian.png'
        },
        {
            name: 'drive',
            avatar: 'https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/driver.png'
        },
        {
            name: 'bus',
            avatar: 'https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/driver.png'
        },
        {
            name: 'guard',
            avatar: 'https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/guard.png'
        },
        {
            name: 'exam',
            avatar: 'https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/exam.png'
        }
    ];
    let output = 'https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/others.png';
    images.map(function (item) {
        if (slug.indexOf(item.name) > -1)
            output = item.avatar;
    });

    return output;
};

const redirectDownloadUrl = ({ uri, queryString = '', addCompanyBatchInfo = true }) => {
    const company = encryption.decryptObject(cookie.get('_company'));
    const batch = getBatch();
    let downloadUrl = `https://reporting.eacademynepal.com${uri}/`;
    if (process.env.VUE_APP_CONNECTION === "QA") {
        downloadUrl = `https://reporting.sahakaryatech.com${uri}/`;
    } else if (process.env.VUE_APP_CONNECTION === "DEV") {
        downloadUrl = `https://reporting.test${uri}/`;
    }

    if (addCompanyBatchInfo === true) {
        downloadUrl += `${company.id}:${batch.id}`;
    }
    downloadUrl += `?${queryString}`;
    window.open(downloadUrl, '_blank');
}


const getMonthWithKey = (key) => {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];
    return months[key];
};


const getDayWithKey = (key) => {
    const day = [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat'
    ];
    return day[key];
};

// const getMonthNameFromString = (key) => {
//     const months = {
//         '01': 'BAISAKH',
//         '02': 'JESTHA',
//         '03': 'ASAR',
//         '04': 'SHRAWAN',
//         '05': 'BHADRA',
//         '06': 'ASOJ',
//         '07': 'KARTIK',
//         '08': 'MANGSIR',
//         '09': 'POUSH',
//         '10': 'MAGH',
//         '11': 'FALGUN',
//         '12': 'CHAITRA',
//     };
//     if (!key) return months;

//     return months[key];
// };

const getMonthNameFromString = (key, onlyValue = false, isForSelectOption = false) => {
    const months = {
        1: { key: '01', value: 'BAISAKH' },
        2: { key: '02', value: 'JESTHA' },
        3: { key: '03', value: 'ASAR' },
        4: { key: '04', value: 'SHRAWAN' },
        5: { key: '05', value: 'BHADRA' },
        6: { key: '06', value: 'ASOJ' },
        7: { key: '07', value: 'KARTIK' },
        8: { key: '08', value: 'MANGSIR' },
        9: { key: '09', value: 'POUSH' },
        10: { key: '10', value: 'MAGH' },
        11: { key: '11', value: 'FALGUN' },
        12: { key: '12', value: 'CHAITRA' },
    };
    // var keysSorted =  Object.keys(months).sort(function(a, b){return a-b});
    // console.log(keysSorted,'key')
    // if (!key) return months;
    if (!key) return months;

    if (isForSelectOption === true) {
        return Object.keys(months).map((value) => {
            return { text: months[value].value, value: months[value].key };
        });
    }

    if (!key && onlyValue === false) return months;

    let output = null;
    try {
        Object.keys(months).map(item => {
            if (months[item].key === key) {
                output = months[item].value;
                throw Error('Here');
            }
        })
    } catch (error) {
        // Exception
    }
    return output;
};

const passDataToExternalDomain = (type = 'reporting') => {
    const iFrame = document.getElementById(type);
    let push = (iFrame.contentWindow);
    let data = {};
    data._communicate_token = ls.get('_comm_tk');
    push.postMessage(data, getServiceDomain(type));
};


const getServiceDomain = (type = 'account') => {
    let transferUri = 'http://localhost:4000';
    let uri = window.location.host.replace('eacademy.', '');
    uri = uri.replace(/^.{2,6}\./, '');

    if (process.env.VUE_APP_CONNECTION === "QA") {
        uri = 'sahakaryatech.com';
    }

    if (uri === 'localhost:8080') {
        transferUri = (type === 'account') ? 'http://localhost:4000' : `http://${type}.test`;
    } else if (uri === 'sahakaryatech.com') {
        transferUri = `https://${type}.` + uri;
    } else {
        transferUri = (type === 'account') ? (`https://${type}.` + uri) : `https://${type}.eacademynepal.com`;
    }
    return transferUri;
};


/**
 * Finter Object From Array
 * @param value
 * @param dataArray
 * @returns {*}
 */
const filterObjectFromArray = (value, dataArray) => {

    if (typeof dataArray !== 'object') throw Error('Invalid data passed');
    let data;
    data = dataArray.filter(function (res) {
        if (res.id === value) {
            return res;
        }
    });
    if (data) return data[0];
    else return false;

    // return {id:1,email:'dilkrishnapila@gmail.com'};
};

/**
 *
 * @param propertyName
 * @param dataArray [{id:1,name:'Selvesan'}]
 * @param key 'id'
 * @returns {*} [1]
 */
const getPropertyFromArrayObject = (propertyName, dataArray, key = false) => {
    if (typeof dataArray !== 'object') throw Error('Invalid data passed');
    if (!dataArray || !propertyName) return [];

    return dataArray.map(function (data) {
        if (key)
            return { [propertyName]: data[propertyName] };
        return data[propertyName];
    });
};

/**
 * Remove false value(empty string, false or zero,undefined,null) from object.
 * @param obj
 * @returns {*}
 */
const cleanObj = (obj) => {
    for (let propName in obj) {

        if (obj.hasOwnProperty(propName) && (!obj[propName])) {
            delete obj[propName];
        }
    }
    return obj;
};

const getThForm = (number) => {
    number = parseInt(number);
    const types = {
        1: 'st',
        2: 'nd',
        3: 'rd',
    };

    if (number >= 4) return 'th';
    return types[number];
};

function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


function convertNumberToWords(amount, only = false) {
    if (!amount) return null;
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
        var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
        var received_n_array = new Array();
        for (var i = 0;i < n_length;i++) {
            received_n_array[i] = number.substr(i, 1);
        }
        for (var i = 9 - n_length, j = 0;i < 9;i++, j++) {
            n_array[i] = received_n_array[j];
        }
        for (var i = 0, j = 1;i < 9;i++, j++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                if (n_array[i] == 1) {
                    n_array[j] = 10 + parseInt(n_array[j]);
                    n_array[i] = 0;
                }
            }
        }
        var value = "";
        for (var i = 0;i < 9;i++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                value = n_array[i] * 10;
            } else {
                value = n_array[i];
            }
            if (value != 0) {
                words_string += words[value] + " ";
            }
            if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Crores ";
            }
            if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Lakhs ";
            }
            if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Thousand ";
            }
            if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                words_string += "Hundred and ";
            } else if (i == 6 && value != 0) {
                words_string += "Hundred ";
            }
        }
        words_string = words_string.split("  ").join(" ");
    }
    if (only === true) return words_string + ' Only/-';
    return words_string;
}

function nepNumberToEng(num) {
    var englishDigits = {
        '०': '0',
        '१': '1',
        '२': '2',
        '३': '3',
        '४': '4',
        '५': '5',
        '६': '6',
        '७': '7',
        '८': '8',
        '९': '9'
    };

    /* Converting Devanagari number to English (js) */
    return num.replace(/[०१२३४५६७८९]/g, function (s) {
        return englishDigits[s];
    });
}

function engNumberToNep(num) {
    var devanagariDigits = {
        '0': '०',
        '1': '१',
        '2': '२',
        '3': '३',
        '4': '४',
        '5': '५',
        '6': '६',
        '7': '७',
        '8': '८',
        '9': '९'
    };

    /* Converting English number to Devangari (js) */
    return num.toString().replace(/[0123456789]/g, function (s) {
        return devanagariDigits[s];
    });
}

const NepaliDate = require('nepali-date');

function validateDate(dateString) {
    if (!dateString) return true;
    let after2000 = true;
    let month = 0;
    let year = 0;
    let day = 0;
    if (dateString && dateString.length === 8) {
        year = dateString.substr(0, 4);
        month = dateString[4] + dateString[5];
        day = dateString.slice(-2);
        dateString = dateString.substr(0, 4) + '-' + dateString[4] + dateString[5] + '-' + dateString.slice(-2);
        if (parseInt(year) <= 2000) after2000 = false;
    }
    try {
        if (after2000) {
            new NepaliDate(dateString).format('YYYY-MM-DD');
            return true
        } else {
            if (parseFloat(month) > 12) return false;
            if (parseFloat(day) > 32) return false;
        }
    } catch (e) {
        return false
    }

    return true;
}

const currencyFormat = (amount, maximumSignificant = 3) => {
    if (!amount) return '';
    const currency = Intl.NumberFormat('hi', {
        style: 'currency',
        currency: 'NPR',
        maximumSignificantDigits: maximumSignificant
    });
    return currency.format(amount);
};

const isCurrentBatch = () => {
    const batch = getBatch();
    if (!batch) return false;

    return (batch.is_current_batch === true);
};

function setBatch(batch, type = 'session') {
    window.sessionStorage.setItem('_batch_se', JSON.stringify(batch));
}


/**
 * Converts json object to query string 
 * @param {*} data 
 * @returns 
 */
const jsonToQueryParam = (data) => {
    return Object.keys(data).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
    }).join('&')
}

function getBatch() {
    return JSON.parse(window.sessionStorage.getItem('_batch_se'));
}

function zoomApiKeys(cId) {
    const keys = {
        // 1: 'Ab4m9Bo8Se2kfkSfl993WQ',
        //Using Jeevan jyoti for temporary purpose
        42: 'b3QtfhbTQZ2-8WISx8KD7g',
        1: 'b3QtfhbTQZ2-8WISx8KD7g',
        26: 'b3QtfhbTQZ2-8WISx8KD7g',
        // 54: 'Ab4m9Bo8Se2kfkSfl993WQ'
    };

    if (keys[cId]) return keys[cId];

    return 'b3QtfhbTQZ2-8WISx8KD7g';
}

/**
 * @return {boolean}
 */
const showUnderMaintenanceMode = () => {
    if (location.search === '?mode=double_slit_experiment_mode') return false;

    return [
        // 'localhost:8080',
        // 'system.gyanpunjaschool.com',
        // 'system.jeevanjyoti.edu.np',
        // 'system.axisacademyedu.com',
        // 'system.baljyotiedu.com',
        // 'system.rosybudsschool.com',
        // 'system.kumudiniedu.com',
        // 'system.jaindraschool.com',
        // 'system.newhorizon.edu.np',
        // 'system.shivagadhischool.com',
        // 'system.shreegyanodaya.edu.np',
        // 'system.adarshmodel.com',
        // 'system.stephenssco.com',
        // 'system.jyotismatischool.com',
        // 'system.hamrocebs.com',
        // 'system.parijatschool.com',
        // 'system.littleplant.edu.np',
        // 'system.northpointschooledu.com',
        // 'system.canalcenterschoolmahalbari.com',
        // 'system.cpebhs.com',
        // 'system.nefedu.com.np',
        // 'system.lahsparasi.com',
        // 'system.gupsedu.com',
        // 'system.hlittlestar.com'
    ].includes(location.host);
};

/**
 * @return {boolean}
 */
const showBannedAlert = () => {
    if (location.search === '?mode=double_slit_experiment_mode') return false;

    return [
        // 'localhost:8080',
        // 'system.gyanpunjaschool.com',
        // 'system.jeevanjyoti.edu.np',
        // 'system.axisacademyedu.com',
        // 'system.baljyotiedu.com',
        // 'system.rosybudsschool.com',
        // 'system.kumudiniedu.com',
        // 'system.hlittlestar.com'
        // 'system.adarshmodel.com',
        // 'system.jyotismatischool.com',
        // 'system.mountviewschool.edu.np',
        // 'system.jaindraschool.com',
        // 'system.newhorizon.edu.np',
        // 'system.shivagadhischool.com',
        // 'system.shreegyanodaya.edu.np',
        // 'system.stephenssco.com',
        // 'system.hamrocebs.com',
        // 'system.parijatschool.com',
        // 'system.littleplant.edu.np',
        // 'system.northpointschooledu.com',
        // 'system.canalcenterschoolmahalbari.com',
        // 'system.cpebhs.com',
        // 'system.nefedu.com.np',
        // 'system.lahsparasi.com',
        // 'system.gupsedu.com',
    ].includes(location.host);
};

/**
 * 1st index is name
 * v => value,
 * s => sortable 
 * @param {*} payload 
 */
const genHeaders = (payload = []) => {
    let header = [];
    payload.map((item) => {
        let itemTypes = item.split(',')
        header.push({
            text: itemTypes[0],
            align: "left",
            width: itemTypes[1] ? parseInt(itemTypes[1]) : null,
            value: itemTypes[0].toLowerCase(),
            sortable: false
        });
    });

    return header;
};

/**
 * Object to query string
 * @param {*} obj 
 * @returns 
 */
const objToQueryString = (obj = {}) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}


const queryStringToJSON = () => {
    var pairs = location.search.slice(1).split('&');

    var result = {};
    pairs.forEach(function (pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
}

export {
    objToQueryString,
    getPropertyFromArrayObject,
    getThForm,
    cleanObj,
    showBannedAlert,
    convertNumberToWords,
    showUnderMaintenanceMode,
    formatNumber,
    getRolesImage,
    filterObjectFromArray,
    nepNumberToEng,
    engNumberToNep,
    validateDate,
    getServiceDomain,
    passDataToExternalDomain,
    getMonthWithKey,
    getDayWithKey,
    getMonthNameFromString,
    isCurrentBatch,
    setBatch,
    zoomApiKeys,
    genHeaders,
    jsonToQueryParam,
    queryStringToJSON,
    redirectDownloadUrl,
    getBatch,
    currencyFormat
};
